

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/protections-droits-recours-conjoints-fait-quebec-services-avocat.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost45 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Quels sont les droits et les protections des conjoints de fait au Québec? - Soumissions Avocat"
        description="Au Québec, ce sont de plus en plus de couples qui choisissent de vivre leur vie en union de fait et de ne jamais officialiser leur union par les liens du mariage. Cette décision est des plus personnelles et en concerne que les principaux intéressés, cela va de soi. Cependant, LIRE PLUS"
        image={LeadImage}>
        <h1>Quels sont les droits et les protections des conjoints de fait au Québec?</h1>

					
					
						<p>Au Québec, ce sont de plus en plus de couples qui choisissent de vivre leur vie en union de fait et de ne jamais officialiser leur union par les liens du mariage. Cette décision est des plus personnelles et en concerne que les principaux intéressés, cela va de soi. Cependant, comme la loi traite les couples mariés et les conjoints de fait avec des mesures différentes, il est essentiel pour ces derniers de connaître les dispositions que la loi prévoit pour eux, mais surtout les droits qu’elle ne prévoit pas.</p>
<p><StaticImage alt="protections-droits-recours-conjoints-fait-quebec-services-avocat" src="../images/protections-droits-recours-conjoints-fait-quebec-services-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Qu’arrive-t-il avec la garde des enfants à la séparation? Qui gardera la maison? Est-ce qu’il y a aura une pension à verser? Ce sont là des interrogations auxquelles se rattachent des réponses parfois surprenantes. Voyez plus bas quels sont les droits et protections des conjoints de fait au Québec.</p>
<h2>Comment définit-on l’union de fait?</h2>
<p>Ce qui définit l’union de fait est réellement son absence de définition légale. En effet, alors que la loi prend la peine de définir ce qui constitue un mariage ou une union civile dans les moindres détails, il faudra se rabattre sur une définition plutôt vague et non officielle de l’union de fait. Il s’agit donc d’une situation dans laquelle deux personnes vivent ensemble dans une relation de couple sans pour autant être mariées.</p>
<p>Bien que le Code civil du Québec n’octroie aucun droit aux conjoints de fait et aucune protection (plus là-dessus) dans les prochaines lignes, certaines lois particulières permettent quant à elles d’octroyer un certain statut légal aux conjoints de fait pour faciliter l’administration de leur vie commune.</p>
<p>C’est notamment le cas des lois fiscales et de celles que l’on qualifie comme ayant un caractère social. Chaque loi présente ses propres exigences quant au nombre d’années requises pour satisfaire les critères de l’union de fait, mais le chiffre varie entre 1 an et 3 ans.</p>
<h2>Les droits des enfants sont-ils affectés en raison du type d’union de leurs parents?</h2>
<p>Aucunement! Le type d’union qui caractérise la relation des parents a pour effet de modifier leurs droits et obligations respectifs, mais n’a aucune incidence sur les droits de l’enfant lui-même. La loi a d’ailleurs prévu des dispositions expresses pour rectifier le tir après des années de stigma à l’endroit des enfants nés hors mariage.</p>
<p>Ainsi, les devoirs relatifs à l’exercice de l’autorité parentale, l’obligation alimentaire et d’éducation existent de la même façon en union de fait qu’en mariage. Ces devoirs subsistent aussi au moment de la séparation. Une pension alimentaire pour enfant peut être demandée que la séparation soit un divorce ou une simple séparation « de fait ».</p>
<p>D’ailleurs, le gouvernement, ayant réellement le meilleur intérêt de l’enfant à cœur indépendamment de sa situation familiale, met à la disposition des conjoints de fait une séance de médiation familiale gratuite. Celle-ci vise à établir les conditions de garde de l’enfant, les droits d’accès ainsi que le montant d’une pension alimentaire le cas échéant. Le processus est le même en ce qui concerne les enfants qu’il ne le serait lors d’un divorce, à l’exception de nécessiter moins de démarches légales.</p>
<h2>Qui décide de la garde des enfants au moment de la séparation?</h2>
<p>Ce sont les parents qui doivent se mettre d’accord sur la garde des enfants au moment de se séparer. Lorsque tout va bien, les parents n’ont qu’à s’entendre sur les modalités de la garde, les droits de visite, d’accès, etc. Toutefois, c’est lorsqu’un désaccord survient que la situation se complique. Il est toujours possible de procéder à une médiation familiale gratuite telle que mentionnée ci-haut.</p>
<p><StaticImage alt="garde-enfant-separation-union-fait-avocat-quebec" src="../images/garde-enfant-separation-union-fait-avocat-quebec.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Toutefois, un désaccord qui persiste implique que l’intervention d’un juge sera nécessaire. Comme les deux parents ont exactement les mêmes droits et obligations à l’égard de leurs enfants, le juge chargé de décider n’aura pas d’opinion biaisée à l’égard de l’un ou l’autre des père et mère.</p>
<p>Le critère qui guidera le juge est l’intérêt de l’enfant. En prenant en compte les besoins physiques, psychologiques et moraux de l’enfant, le juge rendra une décision sur lequel des deux domiciles devrait être celui de l’enfant. Il est aussi possible d’ordonner une garde partagée, du moment que le juge constate la présence d’une certaine stabilité dans la vie de l’enfant.</p>
<h2>La succession en union de fait : les dangers de ne pas se protéger!</h2>
<p>Les droits successoraux sont ceux que tous les conjoints de fait devraient redouter plus que tout. Les couples mariés peuvent bénéficier des protections qu’offrent les lois du mariage, tel que le patrimoine familial. Ce mécanisme permet effectivement d’assurer les intérêts financiers de l’époux ou de l’épouse survivante.</p>
<p>Effectivement, indépendamment de quelconque volonté testamentaire concurrence, le il est obligatoire de procéder à la liquidation et la division du patrimoine familial avant de liquider la succession. Cela implique que l’époux survivant aura droit à la moitié de la valeur du patrimoine, peu importe ce que le mari ou l’épouse décédée avait prévu(e) dans son testament.</p>
<p>De plus, certains contrats de mariage contiennent même une clause appellée « au dernier vivant les biens , permettant effectivement de simplifier le décès et d’attribuer l’ensemble des biens au survivant.</p>
<p>Ces protections n’ont malheureusement aucune application aux conjoints de fait. Lorsque l’un d’eux décède, l’autre n’a aucune protection, à moins que le défunt ait pris la peine de rédiger un testament en bonne et due forme.</p>
<p><strong>Qu’arrive-t-il si aucun testament n’a été préparé par des conjoints de fait, comme c’est le cas pour ces centaines de milliers de québécois? </strong>C’est la succession légale du Code civil du Québec qui devra prendre effet. C’est une avenue que vous voudrez éviter à tout prix, peu importe les attributs spécifiques de votre situation, parce que <strong>les conjoints de fait</strong>, contrairement aux époux mariés, <strong>ne sont pas héritiers l’un de l’autre!</strong></p>
<p>Pour vous donner un exemple d’un scénario pour le moins catastrophique, advenant le cas où des conjoints de fait avec des enfants vivraient en copropriété durant leur union de fait et que l’un d’eux décède sans testament, la quote-part d’un conjoint décédé ira aux enfants.</p>
<p>Ce scénario démontre non seulement l’importance de se protéger adéquatement avec un testament, mais il expose aussi l’absence de protection que la loi accorde aux conjoints vivant en union de fait. Sachant qu’une proportion importante de Québécois font le choix de cette union informelle, il y a lieu de se questionner sur le bien-fondé du droit à cet égard.</p>
<h2>Existe-t-il un recours pour les conjoints de fait lors d’une séparation injuste?</h2>
<p>Nous l’avons vu plus tôt, les époux mariés ne peuvent jamais se retrouver sans le sou lors d’une séparation, car le partage du patrimoine familial assure une certaine sécurité financière. L’absence de protection en union de fait crée donc un important vide juridique qui laisse des tels individus vulnérables. Mais n’existe-t-il réellement aucun recours pour les conjoints de fait laissés pour compte?</p>
<p><StaticImage alt="conjoints-fait-droit-recours-enrichissement-injustifié-conditions-avocat" src="../images/conjoints-fait-droit-recours-enrichissement-injustifié-conditions-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>La seule chance pour un conjoint de fait de se faire indemniser est le recours en <strong>enrichissement injustifié</strong>! Il faut le dire tout de suite, rares sont les situations qui rencontreront les critères d’un tel recours, mais la vie de couple étant ce qu’elle est, il vaut mieux vérifier que de manquer une chance.</p>
<p>Le recours ne enrichissement injustifié vise donc à réparer un déséquilibre injuste dans une relation entre deux personnes lorsqu’une d’entre elles s’est enrichie injustement aux dépens de l’autre. La loi et les décisions des tribunaux exigent que 5 critères cumulatifs se retrouvent.</p>
<ul>
<li><strong>Un enrichissement :</strong> L’un des conjoints doit s’être enrichi, c’est-à-dire, avoir accru son patrimoine lors de la relation.</li>
<li><strong>Un appauvrissement :</strong> À l’inverse, l’autre conjoint se doit d’avoir subi un appauvrissement dans la relation qui peut prendre la forme d’une perte d’argent ou d’un manque à gagner.</li>
<li><strong>Une corrélation :</strong> L’appauvrissement du conjoint lésé doit être le résultat de l’enrichissement de l’autre conjoint et non seulement une simple occasion.</li>
<li><strong>Une absence de justification :</strong> Il existe des raisons, des justifications et des défenses qui peuvent expliquer que l’un des conjoints se soit appauvri alors que l’autre s’est enrichi. Une décision unilatérale de l’un d’eux de ne pas travailler et de ne pas avoir de carrière est un exemple simple.</li>
<li><strong>Une subsidiarité :</strong> Pour entamer un recours en enrichissement injustifié, il faut qu’aucun autre recours ne soit possible en l’espèce; il s’agit donc d’une option de dernier ressort qui vise à corriger une injustice.</li>
</ul>
<p>Pour donner une idée plus concrète du mécanisme, le recours en enrichissement injustifié peut servir à indemniser une femme (ou un homme) qui est restée à la maison pour élever les enfants alors que l’époux a pu mener une carrière pour supporter cette même famille.</p>
<p>Ce dernier a eu l’occasion de s’enrichir dans une carrière prometteuse comme il l’a fait <strong>à cause</strong> de l’apport de madame pendant des années au niveau des responsabilités familiales. Si le mari décidant de lever les feutres avec ses  économies et sa famille déjà bien élevée derrière lui, on se retrouverait devant une situation justifiant probablement un recours en enrichissement injustifié!</p>
<h2>La convention de vie commune rédigée par un avocat est un atout essentiel!</h2>
<p>Alors que la loi ne fait absolument rien pour protéger les conjoints de fait, ce n’est pas une raison de rester les bras croisés à espérer ne pas être l’une des victimes du silence de la loi. Il existe des façons de minimiser les risques reliés à l’union de fait, et la principale est la rédaction d’une <strong>convention de vie commune</strong>. À quoi sert-elle et que contient-elle. Il est possible d’y inclure les aspects suivants :</p>
<ul>
<li><strong>Le partage des actifs au moment de la séparation</strong></li>
<li><strong>La propriété de chacun des biens au début de la relation</strong></li>
<li><strong>Les rôles et devoirs de chacun lors de la relation</strong></li>
<li><strong>Une révision périodique de l’entente pour s’adapter aux changements</strong></li>
<li><strong>Le montant des dettes accumulées</strong></li>
</ul>
<p>Évidemment, tout document encadrant le droit de la famille, plus particulièrement les dispositions relatives aux enfants, est limité par la loi. Il n’est donc pas possible de tout inclure dans une telle convention.</p>
<p><strong>Une des limites de la convention de vie commune est le transfert de biens après le décès! Seul un testament peut produire pareil effet et il doit suivre l’une des 3 formes prescrites par la loi. Si vous souhaitez votre conjoint de fait soit votre héritier ou du moins l’un de vos héritiers en cas de décès, faites rédiger un testament par un avocat!</strong></p>
<h2>Soumissions Avocat est là pour vous aider en cas de séparation de fait avec l’aide d’un avocat!</h2>
<p>Vous avez une famille avec votre conjoint de fait et souhaitez protéger l’intérêt de vos êtres chers? La seule chose à faire, c’est de contacter un avocat sans tarder pour mettre en place les protections légales qui conviennent à votre situation! La rédaction d’une convention de vie commune, d’un testament et d’un mandat donnée en prévision de l’inaptitude est une initiative proactive qui saura vous protéger!</p>
<p><strong>Pour que cela se concrétise, il ne vous reste qu’à remplir le formulaire et nous faire parvenir vos informations pour être rapidement mis en contact avec un avocat de votre région!</strong></p>
<p><strong>Rien ne sert d’attendre, car nos services sont rapides, gratuits et sans engagement, alors contactez-nous!</strong></p>
    </SeoPage>
)
export default BlogPost45
  